import React from "react";
import Video from "../Video";

export default function Stillwell() {
  return (
    <>
      <div>
        <h3 className='centered headerRad'>Eric Stillwell's Interview</h3>
      </div>

      <div className='sectionStyle'>
        <Video
          video={{
            title: "A chat with Eric Stillwell",
            link: "https://www.youtube.com/embed/qcTXJwbHGH8?rel=0",
          }}
        />
      </div>
    </>
  );
}
