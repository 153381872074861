import React from "react";
import logo from "../Images/logo.png";

function NavBar() {
  return (
    <nav className='navbar navbar-dark bg-dark'>
      <div className='container-fluid d-flex align-items-center'>
        <a className='navbar-brand d-flex align-items-center me-3' href='/'>
          <img
            src={logo}
            alt='Logo'
            width='50'
            height='81'
            className='d-inline-block align-text-center'
          />
        </a>
        <p className='navbar-text text-white mb-0'>
          This website started as a message board. During its first years, its
          administrator (Dimitris Psathas) and global moderator (George Giaglis)
          ran some interviews of important people of Star Trek, either in text
          or video form. After running its course as a forum, today it's a place
          that hosts those interviews, as well as Greek translations for some of
          them. Clicking on the names below, you can read or watch those
          interviews. Enjoy!
        </p>
      </div>
    </nav>
  );
}

export default NavBar;
