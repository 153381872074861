import React from "react";
import InterviewGallery from "../InterviewGallery";

export default function Bormanis() {
  const photos = ["photos/Bormanis/bormanis.jpg"];

  return (
    <>
      <div>
        <h3 className='centered headerRad'>André Bormanis's Interview</h3>
      </div>
      <p>
        <b>
          How did your involvement in Star Trek begin and what did your job as
          Science Consultant entail?
        </b>
        <br />
        I became the Science Consultant at the beginning of the 7th season of
        Star Trek: The Next Generation, which was also the beginning of the
        second season of Star Trek: Deep Space Nine, in May, 1993 - a long time
        ago! It was right after I finished graduate school at The George
        Washington University in Washington, DC. I helped the writers and the
        producers of the shows understand and develop science ideas they were
        considering for stories, and also helped them with technical language in
        dialog. That was a big part of my job -- finding the right words when
        the writer inserted the word [TECH] in dialog.
        <br />
        <br />
        <b>
          You also wrote quite a few Star Trek episodes; some of the best ones,
          we might add. Was writing something you had in mind from the beginning
          or something that came up along the way?
        </b>
        <br />
        I definitely wanted to write for Star Trek from the beginning, or at
        least sell a story idea or two to them. That turned out better than I
        expected! I didn't think my chances of become a full-time writer on the
        show were very good - it's very competitive. It took a few years to get
        there, but it worked out well for me.
        <br />
        <br />
        <b>
          Some of the episodes you wrote were parts of double or triple
          episodes. How does a multiple episode work, in terms of the writing
          process? Who writes the story arc and what are the responsibilities of
          the writers of each individual episode?
        </b>
        <br />
        It varies from show to show. All television writing is ultimately a
        group effort - an individual writer may come up with a great story idea,
        but the other writers on staff will then pitch in to develop it. The
        first draft of the script may be written by the person who came up with
        idea, or it may be assigned to another writer. The executive producers
        try to make sure that every writer on staff gets about the same amount
        of assignments and credit each season. I came up with the story for a
        4th season Voyager episode called "Demon." Ken Biller, one of the
        producers, wrote the script. And then in the 5th season Bryan Fuller
        came up with a sequel episode called "Course: Oblivion" which I thought
        was brilliant.
        <br />
        <br />
        <b>
          Are there some episodes or characters from the series that you
          consider your favorites?
        </b>
        <br />
        There are so many to choose from! I've always particularly liked the
        "alien" characters - Spock, Data, Seven of Nine, The Doctor. I'm also
        fond of Neelix, Quark, and of course all of the Captains. Some of my
        favorite episodes are Charlie X, Space Seed, The City on the Edge of
        Forever, Who Mourns for Adonais? (TOS), All Good Things, Darmok, Best of
        Both Worlds (TNG)... there are so many, I'm losing track of the titles.
        Lots of great DS9, Voyager, and Enterprise episodes too, of course.
        <br />
        <br />
        <b>
          Are there any moments of particular interest (funny or otherwise)
          during your working on Star Trek that have stayed in your memory?
        </b>
        <br />
        The first time I stepped on the bridge set of ST:TNG Enterprise was an
        extremely special moment. I couldn't believe I was actually working on
        the show that had been such an inspiration to me for so many years.
        Seeing my first script (Voyager's "Fair Trade") being filmed was also
        really thrilling. They built some really elaborate sets for that one -
        just based on a paragraph or two that I'd written! I remember there was
        lots of humor and good fun on set on the occasions I got to visit. We
        were fortunate that our cast members got along so well with each other,
        which is not always the case in television.
        <br />
        <br />
        <b>
          It is said that the vision of Star Trek stands on two pillars:
          humanistic thought and technology. To what extend do you think these
          two can benefit from each other? Which of the two do you think appeals
          most to Star Trek fans?
        </b>
        <br />
        I think that without humanistic thought and values, our technology would
        be a deadly threat. We're on the brink of wrecking our world for future
        generations as it is, and unless our leaders learn to act for the
        benefit of all mankind, we'll have a very dark future indeed. The thing
        that appeals to me about Star Trek is that it shows that we can all work
        together, bridge our differences, eliminate war as a means of solving
        conflicts, and build a brilliant future in the Milky Way. That's what
        most resonates for me about Star Trek, and many fans over the years have
        said the same thing to me.
        <br />
        <br />
        <b>
          Science has always been an integral part of Star Trek, a fact also
          reflected in your book <i>Star Trek: Science Logs</i>. Would you tell
          us a little about this book and the relationship between Science and
          Star Trek in general?
        </b>
        <br />
        I wrote the book to give fans some insight into how ideas from science
        are integrated into Star Trek stories, and how I approached my job as
        Science Consultant. There is a wonderful feedback loop between science
        and science fiction (SF) - scientists are inspired by SF stories, and
        their breakthroughs inspire new SF.
        <br />
        <br />
        <b>
          We hear a lot lately about research on warp drive and transporter-like
          technology being conducted. What is the progress made so far in these
          fields? How far do you think we could some day go towards reaching
          those or other Trek technologies?
        </b>
        <br />
        In recent years, scientists have pretty much demonstrated that warp
        drive is theoretically possible, and some form of transporter
        technology, at least when it comes to information at the subatomic
        level, may be possible. It's hard to say how far away the realization of
        those technologies might be. But I think there are people alive today
        who will see some form of "warp drive" propulsion become a reality.
        Probably many young people today will live well past 100 years of age.
        By the middle of the 22nd century, I wouldn't rule out warp drive, but
        of course predictions are impossible make that far into the future. In
        thinking about the future, I would encourage everyone to expect the
        unexpected.
        <br />
        Where Star Trek has shown itself to be a little primitive is in computer
        and communication technology. The real world has outpaced Star Trek in
        that regard (with the exception of subspace radio!). Medical technology
        is quickly catching up to Star Trek level capabilities, which I find
        very exciting the older I get!
        <br />
        <br />
        <b>
          There is also much talk recently about the singularity (the AI kind)
          and concerns about its potential risks towards mankind. Do you think
          real AI can be achieved? Do you feel optimistic or concerned about the
          long-term outcome?
        </b>
        <br />
        I think some form of AI will be achieved, eventually, but I don't
        particularly worry about it. I don't think AI will be motivated in the
        same way human beings are, or will have emotions, negative or otherwise,
        and volition the way we do. But I can't rule out the possibility that a
        super-smart AI would start consuming resources on a global scale without
        any concern as to how that would affect us. An AI's indifference to the
        fate of the biosphere would be potentially dangerous, if very carefully
        constructed controls aren't securely in place.
        <br />
        <br />
        <b>
          You are a distinguished member of the Planetary Society. Would you
          tell us a little about this organization and it's mission?
        </b>
        <br />
        The mission of TPS is to promote public interest and support for
        planetary science and exploration. Carl Sagan founded it over thirty
        years ago, and there are tens of thousands of members around the world.
        Voyager's Bob Picardo serves on their Board of Directors. In the next
        couple of years, we plan to launch our own solar sail spacecraft! You
        can learn more about it here: http://planetary.org.
        <br />
        <br />
        <b>
          You held the very important position of Director of Scientific
          Research on <i>Cosmos: A Spacetime Odyssey</i>. How was working on the
          revival of the iconic Carl Sagan's documentary series? To your
          knowledge, is there any chance of a second season of Cosmos being
          produced?
        </b>
        <br />
        Star Trek and Cosmos were both very important shows to me when I was
        growing up. Getting to work on their more recent incarnations has been
        an incredible privilege. There are no plans at the moment that I'm aware
        of for a season two of Cosmos, but I wouldn't rule it out.
        <br />
        <br />
        <b>
          You have been involved as a producer on various other series. Can you
          tell us a little about your work on these shows?
        </b>
        <br />
        Being a producer on Threshold, Eleventh Hour, etc, basically means that
        I was a full-time writer on those shows with a certain level of
        experience under my belt, and some expanded responsibilities for series
        development and production. I really love working as a writer in
        television, even on shows that are not strictly science fiction. It's a
        collaborative and creative process involving lots of interesting and
        talented people.
        <br />
        <br />
        <b>
          In 2010 you were a guest at the Greek convention <i>Terraformers</i>{" "}
          in Sounion. How was your experience there and how is the experience of
          conventions and Star Trek events in general for you?
        </b>
        <br />
        Terraformers was fantastic. The people who put it together were great,
        the setting was great, the fans were great, and the food and wine was
        great! What more could I want? I made many friends there and would go
        back in a heartbeat. Let's do another one soon!
        <br />
        <br />
        <b>
          Are there currently any projects you're working on or any plans for
          the immediate future?
        </b>
        <br />
        I'm working on a feature film script about a little known woman
        scientist from the early 20th century. I'm doing it "on spec" meaning
        nobody is paying me to do it, but I hope to sell it to a studio when
        it's finished. I've also been pitching a TV pilot that's a present-day
        science fiction piece.
      </p>

      <br />
      <h3 className='centered headerRad'>Μετάφραση στα ελληνικά</h3>
      <p>
        <b>
          Πώς ξεκίνησε η συνεργασία σου με το Star Trek και ποια ήταν η δουλειά
          σου ως Επιστημονικός Σύμβουλος;
        </b>
        <br />
        Έγινα ο Επιστημονικός Σύμβουλος στην αρχή της 7ης σεζόν του{" "}
        <i>Star Trek: The Next Generation</i>, η οποία ήταν επίσης η αρχή της
        δεύτερης σεζόν του <i>Star Trek: Deep Space Nine</i>, τον Μάιο του 1993
        ­– πολύ καιρό πριν! Ήταν αμέσως μόλις τελείωσα το μεταπτυχιακό μου στο
        George Washington University στην Ουάσινγκτον. Βοηθούσα τους
        σεναριογράφους και τους παραγωγούς των σειρών να καταλάβουν και να
        αναπτύξουν επιστημονικές ιδέες που επεξεργάζονταν για ιστορίες, και τους
        βοηθούσα επίσης με την τεχνική γλώσσα στους διαλόγους. Αυτό ήταν ένα
        μεγάλο μέρος της δουλειάς μου – το να βρίσκω τις σωστές λέξεις όταν ο
        σεναριογράφος εισήγαγε τη λέξη [TECH] στους διαλόγους.
        <br />
        <br />
        <b>
          Έχεις γράψει επίσης αρκετά επεισόδια για το Star Trek· κάποια από τα
          καλύτερα, θα λέγαμε. Ήταν το γράψιμο κάτι που είχες στο μυαλό σου από
          την αρχή ή κάτι που προέκυψε στην πορεία;
        </b>
        <br />
        Ήθελα σίγουρα να γράψω για το Star Trek από την αρχή, ή τουλάχιστον να
        τους πουλήσω μια-δυο ιδέες. Αυτό εξελίχθηκε καλύτερα απ' ό,τι περίμενα!
        Δεν πίστευα ότι είχα αρκετές πιθανότητες να γίνω μόνιμος σεναριογράφος
        στη σειρά – είναι πολύ ανταγωνιστικό. Χρειάστηκαν μερικά χρόνια για να
        φτάσω εκεί, αλλά εξελίχθηκε καλά για 'μένα.
        <br />
        <br />
        <b>
          Κάποια από τα επεισόδια που έγραψες αποτελούσαν μέρη διπλών ή τριπλών
          επεισοδίων. Πώς λειτουργεί ένα πολλαπλό επεισόδιο, όσον αφορά την
          διαδικασία της συγγραφής των σεναρίων; Ποιος γράφει την συνολική
          ιστορία και ποιες είναι οι αρμοδιότητες των σεναριογράφων του κάθε
          μεμονωμένου επεισοδίου;
        </b>
        <br />
        Διαφέρει από επεισόδιο σε επεισόδιο. Οποιαδήποτε συγγραφή για την
        τηλεόραση είναι εν τέλει μια ομαδική προσπάθεια. Το πρώτο προσχέδιο του
        σεναρίου μπορεί να γραφτεί από το άτομο που είχε την ιδέα, ή μπορεί να
        ανατεθεί σε κάποιον άλλον σεναριογράφο. Οι διευθυντικοί παραγωγοί
        προσπαθούν να εξασφαλίσουν ότι κάθε συγγραφέας στο προσωπικό λαμβάνει το
        ίδιο ποσοστό αναθέσεων και αναφορών στους τίτλους για κάθε σεζόν. Εγώ
        συνέλαβα την ιστορία για ένα επεισόδιο της 4ης σεζόν του Voyager με
        τίτλο “Demon”. Ο Ken Biller, ένας από τους παραγωγούς, έγραψε το
        σενάριο. Και μετά στην 5η σεζόν ο Bryan Fuller συνέλαβε ένα επεισόδιο
        συνέχεια αυτού, με τίτλο “Course: Oblivion” το οποίο θεωρώ ότι ήταν
        καταπληκτικό.
        <br />
        <br />
        <b>
          Υπάρχουν κάποια επεισόδια ή χαρακτήρες από τις σειρές που θεωρείς
          αγαπημένα σου;
        </b>
        <br />
        Υπάρχουν τόσα από τα οποία θα μπορούσα να διαλέξω! Πάντα μου άρεσαν πολύ
        οι “εξωγήινοι” χαρακτήρες – ο Spock, ο Data, η Seven of Nine, ο Γιατρός.
        Μου αρέσει επίσης ο Neelix, ο Quark, και βεβαίως όλοι οι Πλοίαρχοι.
        Κάποια από τα αγαπημένα μου επεισόδια είναι τα{" "}
        <i>
          Charlie X, Space Seed, The City on the Edge of Forever, Who Mourns for
          Adonais?
        </i>{" "}
        (TOS), <i>All Good Things, Darmok, Best of Both Worlds</i> (TNG)...
        είναι τόσα πολλά, χάνω τον λογαριασμό από τους τίτλους. Επίσης πολλά
        εξαιρετικά επεισόδια του DS9, του Voyager, και του Enterprise, φυσικά.
        <br />
        <br />
        <b>
          Υπάρχουν κάποιες στιγμές ιδιαίτερου ενδιαφέροντος (αστείες ή οτιδήποτε
          άλλο) κατά τη διάρκεια των ετών που δούλευες στο Star Trek που έχουν
          μείνει στη μνήμη σου;
        </b>
        <br />Η πρώτη φορά που πάτησα στο σκηνικό της γέφυρας του Enterprise του{" "}
        <i>ST:TNG</i> ήταν μία εξαιρετικά ιδιαίτερη στιγμή. Δεν μπορούσα να
        πιστέψω ότι δούλευα πραγματικά στη σειρά που είχε αποτελέσει για 'μένα
        τόσο μεγάλη έμπνευση για τόσο πολλά χρόνια. Το να δω το πρώτο μου
        σενάριο (το “Fair Trade” του Voyager) να γυρίζεται ήταν επίσης πολύ
        συναρπαστικό. Έφτιαξαν κάποια πολύ λεπτομερή σκηνικά γι' αυτό – με βάση
        απλώς μία-δύο παραγράφους που είχα γράψει! Θυμάμαι ότι υπήρχε πολύ
        χιούμορ και διασκέδαση στο πλατό όταν μπορούσα να το επισκεφτώ. Ήμασταν
        τυχεροί που τα μέλη του καστ τα πήγαιναν τόσο καλά μεταξύ τους, κάτι που
        δεν συμβαίνει πάντα στην τηλεόραση.
        <br />
        <br />
        <b>
          Λέγεται ότι το όραμα του Star Trek στηρίζεται σε δύο πυλώνες: στην
          ανθρωπιστική σκέψη και στην τεχνολογία. Σε ποιο βαθμό πιστεύεις ότι
          αυτά τα δύο μπορούν να ωφελούνται το ένα από το άλλο; Ποιο από τα δύο
          πιστεύεις ότι ελκύει περισσότερο τους φανς του Star Trek;
        </b>
        <br />
        Πιστεύω πως χωρίς ανθρωπιστική σκέψη και αξίες, η τεχνολογία μας θα ήταν
        μία θανάσιμη απειλή. Είμαστε ήδη στα πρόθυρα του να καταστρέψουμε τον
        κόσμο μας για τις επόμενες γενιές, και αν οι ηγέτες μας δεν μάθουν να
        ενεργούν προς όφελος όλου του ανθρώπινου είδους, θα έχουμε πράγματι ένα
        πολύ σκοτεινό μέλλον. Αυτό που με ελκύει εμένα περισσότερο στο Star Trek
        είναι ότι δείχνει πως μπορούμε να δουλέψουμε όλοι μαζί, να γεφυρώσουμε
        τις διαφορές μας, να εξαλείψουμε τον πόλεμο ως μέσο επίλυσης διαμαχιών,
        και να χτίσουμε ένα λαμπρό μέλλον στον Γαλαξία. Αυτό είναι που απηχεί
        περισσότερο σ' εμένα στο Star Trek, και πολλοί φανς όλα αυτά τα χρόνια
        μου έχουν πει το ίδιο πράγμα.
        <br />
        <br />
        <b>
          Η επιστήμη αποτελούσε πάντα ένα κύριο μέρος του Star Trek, ένα γεγονός
          που αντικατοπτρίζεται και στο βιβλίο σου{" "}
          <i>Star Trek: Science Logs</i>. Θα μπορούσες να μας πεις λίγα πράγματα
          γι' αυτό το βιβλίο και για την σχέση μεταξύ επιστήμης και Star Trek
          γενικότερα;
        </b>
        <br />
        Έγραψα αυτό το βιβλίο για να δώσω στους φανς μια ειδική ματιά στο πώς
        ιδέες από την επιστήμη ενσωματώνονται στις ιστορίες του Star Trek, και
        πώς προσέγγιζα τη δουλειά μου ως Επιστημονικός Σύμβουλος. Υπάρχει μια
        υπέροχη ανατροφοδότηση μεταξύ επιστήμης και επιστημονικής φαντασίας (ΕΦ)
        – οι επιστήμονες εμπνέονται από ιστορίες ΕΦ, και οι ανακαλύψεις τους
        εμπνέουν νέα ΕΦ.
        <br />
        <br />
        <b>
          Ακούμε πολλά τελευταία σχετικά με έρευνες που γίνονται για warp drive
          και τεχνολογίες τύπου transporter. Ποια είναι η πρόοδος που έχει
          σημειωθεί μέχρι στιγμής σε αυτά τα πεδία; Μέχρι ποιο σημείο πιστεύεις
          ότι θα μπορούσαμε κάποτε να φτάσουμε στο να αναπτύξουμε τέτοιες ή
          άλλες τεχνολογίες του σύμπαντος του Star Trek;
        </b>
        <br />
        Τα τελευταία χρόνια, οι επιστήμονες έχουν σχεδόν επιδείξει ότι το warp
        drive είναι θεωρητικά δυνατό, και ότι κάποιας μορφής τεχνολογία
        transporter, τουλάχιστον όσον αφορά πληροφορία σε υποατομικό επίπεδο, θα
        μπορούσε να είναι δυνατή. Είναι δύσκολο να πει κανείς πόσο μπορεί να
        απέχει η πραγματοποίηση αυτών των τεχνολογιών. Πιστεύω όμως ότι υπάρχουν
        άνθρωποι ζωντανοί σήμερα που θα δουν κάποια μορφή προώθησης τύπου “warp
        drive” να γίνεται πραγματικότητα. Πιθανόν πολλοί νέοι άνθρωποι σήμερα να
        ζήσουν αρκετά περισσότερο από 100 χρόνια. Μέχρι τα μέσα του 22ου αιώνα,
        δεν θα απέκλεια το warp drive, αλλά βεβαίως είναι αδύνατον να κάνει
        κανείς προβλέψεις για ένα τόσο μακρινό μέλλον. Σκεπτόμενος το μέλλον, θα
        ενθάρρυνα όλους να περιμένουν το απροσδόκητο.
        <br />
        Εκεί που το Star Trek έχει φανεί λίγο πρωτόγωνο είναι στις τεχνολογίες
        υπολογιστών και επικοινωνιών. Ο πραγματικός κόσμος έχει προχωρήσει
        ταχύτερα από το Star Trek σ' αυτόν τον τομέα (με την εξαίρεση του
        subspace ασυρμάτου!). Η ιατρική τεχνολογία πλησιάζει με γρήγορους
        ρυθμούς τις δυνατότητες επιπέδου Star Trek, το οποίο το βρίσκω πολύ
        συναρπαστικό όσο περισσότερο μεγαλώνω!
        <br />
        <br />
        <b>
          Γίνεται πολλή κουβέντα τελευταία σχετικά με τη μοναδικότητα (του
          είδους της Τεχνητής Νοημοσύνης) και υπάρχει ανησυχία σχετικά με τους
          πιθανούς κινδύνους για το ανθρώπινο είδος. Πιστεύεις ότι μπορεί να
          επιτευχθεί πραγματική ΤΝ; Αισθάνεσαι αισιόδοξος ή ανήσυχος για τη
          μακροπρόθεσμη έκβαση;
        </b>
        <br />
        Πιστεύω ότι θα επιτευχθεί μιας μορφής ΤΝ, τελικά, αλλά δεν ανησυχώ και
        πολύ γι' αυτό. Δεν πιστεύω πως η ΤΝ θα έχει κίνητρα με τον ίδιο τρόπο
        που έχουν τα ανθρώπινα όντα, ή ότι θα έχει συναισθήματα, αρνητικά ή
        άλλα, και βούληση με τον τρόπο που την έχουμε εμείς. Όμως δεν μπορώ να
        αποκλείσω την πιθανότητα μια υπερευφυής ΤΝ να αρχίσει να καταναλώνει
        πόρους σε παγκόσμιο επίπεδο χωρίς να ενδιαφέρεται για το πώς αυτό θα
        επηρεάσει εμάς. Μια αδιαφορία της ΤΝ για τη μοίρα της βιόσφαιρας θα
        μπορούσε να είναι επικίνδυνη, αν δεν τοποθετηθούν προσεκτικά
        κατασκευασμένα μέσα ελέγχου.
        <br />
        <br />
        <b>
          Είσαι διακεκριμένο μέλος του Planetary Society. Θα μπορούσες να μας
          μιλήσεις λιγάκι για αυτόν τον οργανισμό και την αποστολή του;
        </b>
        <br />
        Η αποστολή του TPS είναι να προωθήσει το ενδιαφέρον του κοινού και την
        υποστήριξη για την πλανητική επιστήμη και εξερεύνηση. Ο Carl Sagan το
        ίδρυσε πάνω από τριάντα χρόνια πριν, και υπάρχουν δεκάδες χιλιάδες μέλη
        ανά τον κόσμο. Ο Bob Picardo του Voyager υπηρετεί στο Διοικητικό τους
        Συμβούλιο. Στα προσεχή χρόνια, σκοπεύουμε να εκτοξεύσουμε το δικό μας
        διαστημόπλοιο ηλιακού πανιού! Μπορείτε να μάθετε περισσότερα εδώ:
        http://planetary.org.
        <br />
        <br />
        <b>
          Κατείχες την πολύ σημαντική θέση του Διευθυντή Επιστημονικής Έρευνας
          στο <i>Cosmos: A Spacetime Odyssey</i>. Πώς ήταν το να δουλεύεις στην
          αναβίωση της θρυλικής σειράς ντοκιμαντέρ του Carl Sagan; Απ' όσο
          γνωρίζεις, υπάρχει περίπτωση να γυριστεί δεύτερη σεζόν του Cosmos;
        </b>
        <br />
        Το Star Trek και το Cosmos ήταν και τα δύο πολύ σημαντικές σειρές για
        'μένα όταν μεγάλωνα. Το να δουλεύω στις πιο πρόσφατες ενσαρκώσεις τους
        ήταν ένα απίστευτο προνόμιο. Δεν είμαι ενήμερος προς το παρόν για σχέδια
        για δεύτερη σεζόν του Cosmos, αλλά δεν θα το απέκλεια.
        <br />
        <br />
        <b>
          Έχεις συνεργαστεί ως παραγωγός σε διάφορες άλλες σειρές. Μπορείς να
          μας πεις λίγα πράγματα για τη δουλειά σου σ' αυτές;
        </b>
        <br />
        Το να είμαι παραγωγός στο <i>Threshold</i>, στο <i>Eleventh Hour</i>{" "}
        κτλ. σημαίνει βασικά ότι ήμουν σεναριογράφος πλήρους ωραρίου σ' αυτές
        τις σειρές με ένα επίπεδο εμπειρίας πίσω μου, και μερικές διευρυμένες
        ευθύνες για την ανάπτυξη και την παραγωγή των σειρών. Απολαμβάνω πολύ να
        δουλεύω σαν σεναριογράφος στην τηλεόραση, ακόμη και σε σειρές που δεν
        είναι απαραίτητα επιστημονικής φαντασίας. Είναι μια συνεργατική και
        δημιουργική διαδικασία που περιλαμβάνει πολλούς ενδιαφέροντες και
        ταλαντούχους ανθρώπους.
        <br />
        <br />
        <b>
          Το 2010 ήσουν καλεσμένος στο ελληνικό convention <i>Terraformers</i>{" "}
          στο Σούνιο. Πώς ήταν η εμπειρία σου εκεί και πώς είναι η εμπειρία των
          conventions και των διάφορων Star Trek εκδηλώσεων για 'σένα;
        </b>
        <br />
        Το Terraformers ήταν φανταστικό. Οι διοργανωτές ήταν καταπληκτικοί, η
        τοποθεσία ήταν καταπληκτική, οι φανς ήταν καταπληκτικοί, και το φαγητό
        και το κρασί ήταν καταπληκτικά! Τι άλλο θα μπορούσα να θέλω; Έκανα
        πολλούς φίλους εκεί και θα ξαναπήγαινα χωρίς να το σκεφτώ στιγμή! Ας
        κάνουμε ένα ακόμη σύντομα!
        <br />
        <br />
        <b>
          Υπάρχει κάτι πάνω στο οποίο δουλεύεις αυτό το διάστημα ή σχέδια για το
          προσεχές μέλλον;
        </b>
        <br />
        Δουλεύω πάνω σε ένα σενάριο κινηματογραφικής ταινίας για μία σχετικά
        άγνωστη γυναίκα επιστήμονα στις αρχές του 20ού αιώνα. Το κάνω “on spec”,
        το οποίο σημαίνει ότι δεν με πληρώνει κάποιος για να το κάνω, αλλά
        ελπίζω ότι θα το πουλήσω σε κάποιο στούντιο όταν το τελειώσω. Έχω
        προτείνει επίσης έναν πιλότο για μια τηλεοπτική σειρά επιστημονικής
        φαντασίας που εξελίσσεται στο παρόν.
      </p>

      <br />
      <InterviewGallery photos={photos} />
    </>
  );
}
