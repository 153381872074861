import React from "react";
import InterviewGallery from "../InterviewGallery";

export default function Hertzler() {
  const photos = [];
  for (let i = 1; i <= 7; i++) {
    photos[i] = `photos/Hertzler/hertz${i}.jpg`;
  }

  return (
    <>
      <div>
        <h3 className='centered headerRad'>J.G. Hertzler's Interview</h3>
      </div>
      <p>
        <b>
          You are most widely known in the world of Star Trek for your portrayal
          of the Klingon General Martok. However, you have also played numerous
          other characters. You are, in fact, one of very few actors that have
          played seven or more different characters in Star Trek. Was it your
          intention to audition for and play that many parts, or was it the
          production that called you all those times? How did Martok,
          specifically, come along?
        </b>
        <br />
        Actors in Hollywood... not stars, not hot young newbies in Hollywood,
        but character actors in Hollywood usually audition for perhaps 20 or 30
        roles in a week or two. The average of booking a job to the number of
        auditions... was approximately 100 to 1. 100 auditions or interviews and
        usually 1 job came out of it. But it’s the performing arts, it’s show
        biz and we actors accept that horrible ratio. That’s what we signed up
        for. Regarding Martok, I was already on the Paramount studio lot
        auditioning for some other show, I cannot recall what it was. The
        casting director for DS9 walked by and and handed me a script and
        suggested I take a look at General Martok. Basically I am a big guy,
        football build with a big voice so if I could act at all he figured I
        would have a decent chance at getting cast. I went in... got angrier in
        my frustration about Hollywood, my life, my acting ability, the world
        tensions, no money etc. etc. etc. I threw a chair into a plaster wall
        where it stuck, ripped off part of my thumbnail, bled on the rug and got
        the role. If they wanted blood, they got it. And I got it. Martok was
        supposed to be a one show part, but they liked the character with me
        inside him and so they brought him back as a major Klingon in the
        history of Klingons on Star Trek. I was very lucky. Its very very rare
        that an actor gets to audition for a role that totally fits him or her.
        But sometimes ya get lucky.
        <br />
        <br />
        <b>
          In many ways, Martok was not your typical Klingon. He wasn’t so
          violent all the time, he was more considerate, more sophisticated. Was
          that your personal input on the character or something the writers
          wanted for the part?
        </b>
        <br />
        I brought myself to the role. Once the writers noticed who Martok was
        through me... they began to write for THAT character, not some other
        generic Klingon warrior among a battalion of warriors.
        <br />
        <br />
        <b>
          Was the Klingon makeup, and an one-eyed one at that, an obstacle to
          acting?
        </b>
        <br />
        When they brought Martok back from his torturing by the Jem’Hadar,
        either on their planet or some enormous starship... I only had one eye
        left from the brutality. The producers wanted to give me an “artificial”
        eye but I refused that in the name of KLINGON wisdom... and insisted the
        scars be left as they are... that no Klingon would accept an artificial
        eye or lose this battle scars that bespeak his history. They okayed it
        so that’s how Martok ended up with one eye.
        <br />
        <br />
        <b>
          What about the Klingon language? Was difficult to speak? Was it any
          fun for you saying those lines?
        </b>
        <br />
        A lot of fun to speak and to sing! Love the songs. Impossibly difficult
        to learn. I cannot believe that many fans have dedicated their time to
        actually learning the language, the structures and the vocabulary. That
        is stunning... I wish I had that kind of patience.
        <br />
        <br />
        <b>
          Is playing an alien more difficult than playing a human? How do you
          immerse yourself in such a part?
        </b>
        <br />
        I’m a character actor. My whole career is playing aliens. Sometimes they
        are Kings of England, I even played Jason in the Greek play MEDEA, and
        Pentheus in THE BACCHAE, the hardest plays to do are the GREEK plays...
        incredibly demanding and they go so deep into human nature and the
        corruption thereof that actors rarely successfully plumb the depths of
        the GREEKS. By the way in THE BACCHAE, my mother AGAVE was played by
        IRENE PAPPAS and we were directed by MICHAEL CACOYANNIS. What a wonder
        it was to play with Irene Pappas! That’s an actor’s life. We have soooo
        many incredible moments but we also have those deep valleys of despair
        when everything goes straight to hell. But... we learn to deal with it.
        That is the artist’s life.
        <br />
        <br />
        <b>
          You have done a lot of Shakespeare. In fact, many Star Trek actors are
          Shakespearian trained and, as we understand, you were even having
          Shakespeare seminars at the Paramount lot. Do you feel there is a
          connection? Is Star Trek (or life itself!) a large Shakespearian play?
        </b>
        <br />
        Shakespeare dealt with Human Frailty, love... hate... betrayal...
        courage... fear... truth and beauty, ugliness and lies. Important
        things. So does Roddenberry. All of his scripts dealt with human
        drama... issues that were ripping societies apart. But all in a Sci Fi
        context which allowed us all to watch and learn but still at a distance.
        I am teaching Shakespeare right now as a matter of fact, in upstate New
        York. I love it. At Paramount Pictures, Sir Patrick Stewart held a
        Shakespeare workshop every Saturday for about 6 hours for about 15 of
        us... a tremendously fascinating group of actors from all over
        Hollywood. I was lucky to be there. It was a remarkable time... as I was
        just saying about an actor’s life... extraordinary moments!
        <br />
        <br />
        <b>
          Aside from acting yourself, you also teach acting at Cornell
          University. Is teaching a rewarding experience for you? Is the role of
          the teacher as much important as acting is to you?
        </b>
        <br />
        I love teaching more than I love acting. Oddly, I really don’t like
        being the one on stage that everyone is looking at. I much prefer
        helping others execute their roles to most effective way possible...
        bring out the absolute best in an actor and help them over rough spots.
        Not teaching regularly at Cornell University, right now... but holding
        private classes on Shakespeare. Just finished first term and readying
        for a second.
        <br />
        <br />
        <b>
          You co-wrote <i>The Left Hand of Destiny</i> novel diptych with
          Jeffrey Lang, in which we see the continuation of Martok's story. What
          was your motivation? Was it a commercial move or a personal need? Did
          you somehow feel connected to the character and wanted to continue his
          story? If yes, do you still feel that kind of connection, or is it
          something that belongs to the past?
        </b>
        <br />
        The Title is mine and the outline of the story is mine and I wrote all
        of the first book and some of the second. Jeffery came aboard and did an
        amazing job at improving everything that I had done and adding his own
        talent and creativity into the mix especially in the second book.
        Totally commercial job... Simon and Schuster came to me to see if I were
        interested in writing a book and I said ofcourse. They wanted a
        Arthurian legend kind of tale so that’s what Jeffery and I did. We had a
        great editor named Marco Palmieri who kept us on track and suggested
        valuable ideas throughout. It was a great experience. I wish it were
        made into an AUDIO BOOK or a mini series on TV.
        <br />
        <br />
        <b>
          You were going to play a character in <i>Star Trek: Renegades</i>, but
          you were elected to the Town Council of Ulysses, NY. What prompted you
          to get actively involved in politics?
        </b>
        <br />
        They have nothing to do with each other. Renegades was scheduled to
        shoot in the Summer when I was available to come back from the East
        Coast and do the film. Then they changed to the Fall and since my
        daughter was just starting High School... it was more important for me
        to be here with her than doing Renegades. I think it was better for the
        film also... they found a great actor and huge man to play the role, did
        a fantastic job, I understand.
        <br />
        <br />
        <b>
          What do you think about the Federation society of Star Trek? Could
          this ever be a possible future for humanity? What do you think Star
          Trek’s social message is?
        </b>
        <br />
        It’s the only hope for Mankind... otherwise we will keep fighting
        internecine wars for the rest of our days and it will all come to an
        end. Basically, I think that was Roddenberry’s thought as well... I
        think he would be disheartened but not surprised by all the wars in
        progress, all the debates and denials that are going on today. All the
        hate and violence is so very sad.
        <br />
        <br />
        <b>
          Are there currently any projects you’re working on or any plans for
          the immediate future?
        </b>
        <br />
        Several. Teaching Shakespeare and developing a production of Shakespeare
        in the immediate future. I have three screenplays out there circulating.
        The first is DANCING WITH SANCHO PANZA, about the 1936 Spanish Civil
        War... It’s so near going to production it hurts me! So NEAR but not
        BEGUN... YET! The second is REPUBLIC, about the immediate future of
        America if we don’t move toward the Roddenberry hope for the future...
        and the third is HEARTLAND about the destruction of American small towns
        by big BOX STORES like Walmart. They are not comedies. But they have a
        lot of humor. Every drama should. We’ll see.
      </p>

      <br />
      <InterviewGallery photos={photos} />
    </>
  );
}
