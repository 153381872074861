import React from "react";

export default function Sternbach() {
  return (
    <>
      <div>
        <h3 className='centered headerRad'>Rick Sternbach's Interview</h3>
      </div>
      <p>
        <b>
          How did you get involved in Star Trek? What were your responsibilities
          as a senior illustrator and designer?
        </b>
        <br />
        I was lucky enough to meet up with Gene Roddenberry at a showing of "The
        Cage" at Yale University in Connecticut in 1974; by that point I was
        already a working SF and astronomical artist, so I brought some samples
        to show. There had been preliminary plans to make a Star Trek film and I
        wondered about getting into the industry. We got to talking for a few
        hours after the screening, though it would be a few years until I would
        meet with the art department folks for the aborted Phase II production
        in Los Angeles in 1977, and eventually got hired on for Star Trek: The
        Motion Picture early in 1978. My job was to create designs for ships and
        props that were mentioned in the movie or TV show scripts, draw
        occasional storyboard sequences to assist the visual effects department,
        and produce illustrations of sets to show the producers what things
        would look like before construction began. Most of the time, however, a
        quick 3D model built from lightweight foamcore showed the producers,
        director, and camera folks a lot more than one or two sketches could.
        <br />
        <br />
        <b>
          How do you proceed on designing a ship or a station? How long does the
          whole process usually take?
        </b>
        <br />
        Depends on the ship. Some designs that we would see only once, an “alien
        ship of the week,” might only take a scribbly sketch on a scrap of paper
        and the VFX folks would get a physical model or a CG model built and
        then animated. Other major ships, like Voyager, took a total of five
        months of sketching and CG roughs and redesigns and eventually full
        scale blueprints.
        <br />
        <br />
        <b>
          Does form come before function or function before form? When you
          designed the U.S.S. Voyager's variable-geometry warp nacelles or the
          multi-vector assault mode of U.S.S. Prometheus, was it something that
          you first imagined and the writers used afterwards, in order to create
          interesting scripts? Or was it the other way round, i.e. the writers
          demanded some kind of innovation and you made it happen?
        </b>
        <br />
        Most of the time the scripts called for a certain kind of ship or
        structure or prop, and if the writers didn’t specify certain bits, we
        simply designed them according to what we knew of the stylistic
        requirements (like Starfleet vs. Klingon). Because Trek is a science
        fiction media franchise, and for a designer like myself, it really
        requires a mix of engineering plausibility and visual coolness. Most of
        the time, that works out fine. For things like Voyager’s nacelles, the
        producers asked for something to move on the ship, we gave them four or
        five different possibilities, they picked the moving pylons, and those
        got drawn into the blueprints. Prometheus was described in the script
        from the start as being able to split into pieces, so that’s how it was
        designed. Some basic things about ships like Voyager were designed in,
        just because I knew what all good Starfleet ships have, and the
        producers were given an entire booklet about those details. On occasion,
        they’d apply one of those details to a story. Producer Jeri Taylor asked
        for antimatter pods to be used as depth charges, and I was happy to help
        that happen.
        <br />
        <br />
        <b>
          When you design alien ships, do you want them to reflect in some way
          the “character” of the species?
        </b>
        <br />
        It doesn’t always work out like that, but in some cases, the shapes and
        colors and other design elements from a culture’s makeup or costumes or
        interior sets can get worked into an exterior ship design. With the
        Hirogen ships, some interior bits were definitely incorporated, like the
        long pointy nacelles. With the Cardassians, the Galor class cruiser and
        the DS9 station deliberately reflected that culture.
        <br />
        <br />
        <b>
          Speaking of alien spacecraft, you designed the model of Deep Space 9
          station with Herman Zimmerman. Would you tell us a little about the
          creation of this design?
        </b>
        <br />
        While the producers were evolving their backstory about the station, we
        attempted to keep up with evolving designs, from an ancient alien place
        not built by the Cardassians, to a crazy patchwork of space structures,
        to a stacked design like an oil rig, through the final design of a
        central core and nested rings and tall pylons. When it became clear that
        the Cardassians did build the station, we added the right stylistic
        details and I drew the blueprints up for construction.
        <br />
        <br />
        <b>
          You wrote the{" "}
          <i>
            Star Trek: The Next Generation USS Enterprise NCC-1701-D Blueprints
          </i>
          . What process do you follow in order to complete such an enormous
          task as that of drafting blueprints for a whole starship?
        </b>
        <br />
        It’s basically like blueprinting any large structure; it’s just that
        this one was a fictional spaceship. We knew the size, we knew the
        hardware filling the interior, as well as the habitable rooms, so it
        really came down to laying out big sheets of drafting vellum and mylar
        and measuring and drawing things to scale. A lot of the ship was
        unknown, of course, so those spaces needed to be invented with the help
        of folks like Todd Guenther. A lot of the interior elements were
        repeatable, like rooms, so the actual number of unique items was
        manageable.
        <br />
        <br />
        <b>
          You also drafted the blueprints for the Enterprise-E. Due to various
          things said in <i>First Contact</i> and <i>Nemesis</i>, there has been
          much confusion about the actual number of decks on this ship. Could
          you shed some light on the subject?
        </b>
        <br />
        Not a lot. :) I think it came down to the writers not checking, but that
        happens occasionally, so we note it and move on.
        <br />
        <br />
        <b>
          Do you have any favorite designs among all the starships and stations
          presented in Star Trek?
        </b>
        <br />
        As for ships I didn’t work on, the 1701-Refit is one of the coolest
        designs in the Trek universe. Within the batch I worked on, it’s still a
        toss-up between Voyager and the Vor’Cha class Klingon cruiser.
        <br />
        <br />
        <b>
          The PADD was also one of your creations. Did you think back then that
          we would have similar devices, like the tablets of today, so soon in
          real life?
        </b>
        <br />
        Well, I knew they’d be coming along, I just wasn’t sure exactly when.
        All of us who have designed hardware for science fiction productions
        have kept our eyes on computer developments for years. So tablets and
        smartphones and such aren’t exactly surprising, but they are amazingly
        cool. I’ve been able to run a hi-def slide show from an iPod, and when I
        started out in the early 1970s it took two film slide projectors and a
        dissolve unit to get the same kind of basic visual look. And that’s
        aside from shooting the images with a 35mm SLR and getting the slides
        made.
        <br />
        <br />
        <b>
          Star Trek has if fact influenced and in some cases even triggered
          real-life technological advancements. Would you like to comment on
          that?
        </b>
        <br />
        That’s a pretty broad statement, but it’s well known that a lot of
        people in the sciences and engineering have been inspired by shows like
        Star Trek and went into their chosen fields because of the possibilities
        presented in the shows.
        <br />
        <br />
        <b>
          As a technical consultant to the script staff, how difficult was
          maintaining the chronological continuity among the enormous amount of
          scripts?
        </b>
        <br />
        Keeping track of the technological concepts offered to the writers and
        producers was actually pretty easy, especially with our desktop
        computers for creating and storing the memos and drawings. The writers
        were good listeners, and they got to understand how the ships and
        systems normally worked based on our internal tech booklets and script
        notes. When they needed something to go haywire or operate in a
        different way, we worked that out and offered suggestions.
        <br />
        <br />
        <b>
          How did you come up with all the technical terminology you've invented
          over the years?
        </b>
        <br />
        I’ve followed science and technology ever since I was a kid, watched
        science fiction films, documentaries, read space books, built model
        rockets, and so on. I went into art instead of hands-on engineering, but
        one should remember that every major space project we’ve ever attempted
        started with technical art. Aside from knowing how to draw and paint,
        it’s good to know about materials and processes, thrust-to-weight
        ratios, propulsion and power generation systems, environmental control
        and life support issues, celestial mechanics and guidance and
        navigation. A little of everything; mostly through reading and listening
        and talking with experts over the years.
        <br />
        <br />
        <b>
          You co-authored <i>The Next Generation, Deep Space Nine</i> &{" "}
          <i>Voyager Technical Manuals</i>. How did you manage to maintain
          scientific plausibility in the concepts described both in the series
          and the books? Why hasn't the Voyager TM been published?
        </b>
        <br />
        Writing the tech manuals really connects to the previous answer. Once
        you know something of how real space systems operate, it isn’t that much
        of a stretch to invent new ones, to extrapolate developments in physics
        and other areas to give the readers some interesting future ideas. There
        are lots of little mental details that do enter into a new fictional
        tool or system, like materials, energies required, construction time,
        mass, and so on, but a lot of those can be established as internal rules
        during production. And it’s that internal consistency that has been a
        hallmark of Star Trek since the very beginning.
        <br />
        Regarding the Voyager manual, it’s a matter of the time and money
        resources the publisher licensees might want to apply to a Voyager book
        versus possible sales, and so far they’ve not expressed enough interest.
        Simple as that.
        <br />
        <br />
        <b>
          Which of all the types of work you did for Star Trek -designing ships
          and stations, drafting blueprints, creating models and props,
          illustration etc.- was the most satisfying for you?
        </b>
        <br />
        It’s all satisfying, since it’s all one big process of creating things
        apparently out of thin air. The internal rules I spoke of keep it all
        from exploding into silliness, even with accepting some slightly
        implausible concepts for the sake of drama, and I like that.
        <br />
        <br />
        <b>
          Would you share with us some of your favorite moments of all those
          years of working on Star Trek?
        </b>
        <br />
        There are a few tales I’ve told on occasion, sure. In the very early
        days of working with our Macintosh computers, Patrick Stewart would come
        upstairs to the art department and ask Mike Okuda and myself techy
        questions about his own Mac. He once wondered, 'I know Command-X makes a
        bit of text disappear... and I know you can bring it back, but where
        does it go? It’s very disconcerting.'
        <br />
        On Star Trek: The Motion Picture, director Robert Wise deliberately held
        up filming, waiting for me to get to stage to tell Jimmy Doohan
        (Engineer Montgomery Scott) which controls to activate in the Starfleet
        travel pod; I had designed the touch displays.
        <br />
        <br />
        <b>
          You have worked for many science magazines and research institutions.
          In which ways does that work differ from creating designs for TV and
          films?
        </b>
        <br />
        It’s very similar, but differs a bit in that more realistic science and
        engineering come into play. When portraying historical or future space
        exploration scenes, it’s good to know about the real space systems I
        mentioned earlier, and for those of us who are astronomical artists
        specifically, it’s also good to know about the planets and moons and
        other stellar objects we’re visualizing. Some of get very much into
        cratering, vulcanism, cometary bodies, gas giant atmospheres, stellar
        formation and evolution, and related topics.
        <br />
        <br />
        <b>
          You worked for Carl Sagan's <i>Cosmos</i>. What do you think about the
          show's recent revival?
        </b>
        <br />
        I think the new show is an decent introduction to the universe and some
        of the personalities who have studied it. I did not find this second
        version as intellectually challenging as the first one, and I found some
        of the CGI space effects disappointing. I would recommend that kids or
        adults who aren’t terribly familiar with any of the material to watch
        the new show, then watch the original and read the original COSMOS book.
        They’ll get much more out of the experience.
        <br />
        <br />
        <b>What projects are you currently working on?</b>
        <br />
        I’ve stepped off a 42 year treadmill run for a bit to reorganize, pick
        and choose some drawing, CG, and physical scale model projects, and
        reestablish contact with friends and clients. Got a few more things
        coming.
      </p>

      <br />
      <h3 className='centered headerRad'>Μετάφραση στα ελληνικά</h3>
      <p>
        <b>
          Πώς ξεκινήσατε να δουλεύετε για το Star Trek; Ποιες ήταν οι
          αρμοδιότητες που είχατε ως κύριος εικονογράφος και σχεδιαστής;
        </b>
        <br />
        Ήμουν αρκετά τυχερός ώστε να γνωρίσω τον Gene Roddenberry σε μία προβολή
        του “The Cage” στο Πανεπιστήμιο Yale University στο Connecticut το 1974·
        μέχρι τότε δούλευα ήδη σαν καλλιτέχνης επιστημονικής φαντασίας και
        αστρονομίας, οπότε έφερα κάποια δείγματα να του δείξω. Υπήρχαν
        προκαταρτικά πλάνα για να γίνει μία ταινία Star Trek και σκέφτηκα μήπως
        μπω στη βιομηχανία. Αρχίσαμε να μιλάμε μερικές ώρες μετά την προβολή, αν
        και θα περνούσαν μερικά χρόνια μέχρι να συναντηθώ με τα παιδιά στο
        καλλιτεχνικό τμήμα για την ακυρωμένη παραγωγή του Phase II στο Λος
        Άντζελες το 1977, και τελικά με προσέλαβαν στο Star Trek: The Motion
        Picture στις αρχές του 1978. Η δουλειά μου ήταν να φτιάχνω σχέδια για
        πλοία και αντικείμενα που αναφέρονταν στην ταινία ή στα σενάρια της
        τηλεόρασης, να σχεδιάζω περιστασιακά `storyboard' ακολουθίες για να
        βοηθώ το τμήμα των οπτικών εφέ, και να φτιάχνω αναπαραστάσεις σκηνικών
        για να δείχνω στους παραγωγούς πώς θα φαίνονται τα πράγματα πριν
        ξεκινήσει η κατασκευή. Τον περισσότερο καιρό, ωστόσο, ένα γρήγορο 3D
        μοντέλο φτιαγμένο από ελαφρύ αφρολέξ έδειχνε στους παραγωγούς, τον
        σκηνοθέτη και τα παιδιά στις κάμερες πολλά περισσότερα απ' ό,τι θα
        μπορούσαν να δείξουν ένα ή δύο σκίτσα.
        <br />
        <br />
        <b>
          Ποια διαδικασία ακολουθείτε όταν σχεδιάζετε ένα πλοίο ή έναν σταθμό;
          Πόσος χρόνος χρειάζεται συνήθως για να ολοκληρωθεί η διαδικασία;
        </b>
        <br />
        Εξαρτάται από το πλοίο. Μερικά σχέδια που βλέπαμε μόνο μία φορά, ένα
        “εξωγήινο σκάφος της εβδομάδας”, μπορεί να χρειαζόταν μόνο ένα πρόχειρο
        σκίτσο σ' ένα κομμάτι χαρτί και τα παιδιά στα οπτικά εφέ θα έφτιαχναν
        ένα φυσικό μοντέλο ή ένα μοντέλο CG στο οποίο θα έδιναν έπειτα κίνηση.
        Άλλα κύρια σκάφη, όπως το Voyager, χρειάστηκαν πέντε ολόκληρους μήνες
        σχεδιασμού και CG διορθώσεις και επανασχεδιασμούς και τελικώς προσχέδια
        πλήρους μεγέθους.
        <br />
        <br />
        <b>
          Η μορφή προηγείται της λειτουργίας ή η λειτουργία προηγείται της
          μορφής; Όταν σχεδιάσατε τα ατρακτίδια δίνης μεταβλητής γεωμετρίας του
          U.S.S. Voyager ή τον τύπο πολυ-διανυσματικής επίθεσης του U.S.S.
          Prometheus, ήταν κάτι που φανταστήκατε εσείς πρώτα και οι
          σεναριογράφοι χρησιμοποίησαν αργότερα, ώστε να δημιουργήσουν
          ενδιαφέροντα σενάρια; Ή ήταν το αντίστροφο, π.χ. οι σεναριογράφοι
          ζήτησαν κάποια καινοτομία και εσείς το πραγματοποιήσατε;
        </b>
        <br />
        Τις περισσότερες φορές τα σενάρια χρειάζονταν ένα πλοίο, μια κατασκευής
        ή ένα αντικείμενο κάποιου είδους, και αν οι σεναριογράφοι δεν
        διευκρίνιζαν συγκεκριμένα πράγματα, απλά τα σχεδιάζαμε σύμφωνα με όσα
        γνωρίζαμε για τις στυλιστικές απαιτήσεις (όπως Αστροστόλος εναντίον
        Klingon). Επειδή το Trek είναι ένα franchise επιστημονικής φαντασίας,
        και για έναν σχεδιαστή όπως εγώ, χρειάζεται πραγματικά μια μίξη
        μηχανικής αληθοφάνειας και οπτικής ομορφιάς. Τις περισσότερες φορές,
        αυτό δουλεύει μια χαρά. Για πράγματα όπως τα ατρακτίδια του Voyager, οι
        παραγωγοί ζήτησαν κάτι που να κινείται στο σκάφος, εμείς τους δώσαμε
        τέσσερις ή πέντε διαφορετικές πιθανότητες, εκείνοι διάλεξαν τους
        κινούμενους πυλώνες, και αυτοί σχεδιάστηκαν στα προσχέδια. Για το
        Prometheus τα σενάρια περιέγραφαν εξαρχής ότι θα μπορούσε να χωρίζεται
        σε κομμάτια, οπότε σχεδιάστηκε έτσι. Κάποια βασικά πράγματα για πλοία
        όπως το Voyager σχεδιάστηκαν, απλώς επειδή ήξερα τι πρέπει να έχουν όλα
        τα καλά πλοία του Αστροστόλου, και δόθηκε στους παραγωγούς ένα ολόκληρο
        βιβλιαράκι σχετικά με αυτές τις λεπτομέρειες. Σε κάποιες περιπτώσεις,
        εφάρμοζαν κάποια από αυτές τις λεπτομέρειες σε μια ιστορία. Η παραγωγός
        Jeri Taylor ζήτησε να χρησιμοποιηθούν δοχεία αντιύλης ως βόμβες βάθους,
        και χάρηκα που μπόρεσα να το πραγματοποιήσω.
        <br />
        <br />
        <b>
          Όταν σχεδιάζετε εξωγήινα πλοία, θέλετε να αντανακλούν κατά κάποιον
          τρόπο τον “χαρακτήρα” του συγκεκριμένου είδους;
        </b>
        <br />
        Δεν λειτουργεί πάντα έτσι, αλλά σε κάποιες περιπτώσεις, τα σχήματα και
        τα χρώματα, καθώς και άλλα στοιχεία σχεδιασμού από το makeup ή τα
        κοστούμια ή τα σκηνικά εσωτερικού χώρου μιας φυλής, μπορούν να
        εφαρμοστούν και στον εξωτερικό σχεδιασμό ενός πλοίου. Στα πλοία των
        Hirogen, κάποια εσωτερικά στοιχεία ενσωματώθηκαν εντελώς, όπως τα μεγάλα
        μυτερά ατρακτίδια. Με τους Cardassians, το Galor class cruiser και ο
        σταθμός DS9 αντανακλούσαν σκόπιμα αυτήν την κουλτούρα.
        <br />
        <br />
        <b>
          Μιλώντας για εξωγήινα σκάφη, σχεδιάσατε το μοντέλο του σταθμού Deep
          Space 9 μαζί με τον Herman Zimmerman. Θα θέλατε να μας πείτε λίγα
          πράγματα για τη δημιουργία αυτού του σχεδίου;
        </b>
        <br />
        Καθώς οι παραγωγοί ανέπτυσσαν το υπόβαθρο της ιστορίας του σταθμού,
        εμείς προσπαθούσαμε να προλαβαίνουμε τις αλλαγές στα εξελισσόμενα
        σχέδια, ξεκινώντας από ένα αρχαίο εξωγήινο μέρος όχι κατασκευασμένο από
        τους Cardassians, συνεχίζοντας ως ένα τρελό μπάλωμα από διαστημικές
        κατασκευές, περνώντας από έναν στοιβαγμένο σχεδιασμό σαν εξέδρα άντλησης
        πετρελαίου, και καταλήγοντας στο τελικό σχέδιο με έναν κεντρικό πυρήνα,
        ομόκεντρα δαχτυλίδια και ψηλούς πυλώνες. Όταν ξεκαθαρίστηκε ότι τον
        σταθμό τον κατασκεύασαν τελικά οι Cardassians, προσθέσαμε και τις
        κατάλληλες στυλιστικές λεπτομέρειες και έκανα τα προσχέδια για την
        κατασκευή.
        <br />
        <br />
        <b>
          Γράψατε το{" "}
          <i>
            Star Trek: The Next Generation USS Enterprise NCC-1701-D Blueprints
          </i>
          . Ποια διαδικασία ακολουθείτε ώστε να ολοκληρώσετε ένα τόσο μεγάλο
          έργο όπως τη δημιουργία προσχεδίων για ένα ολόκληρο αστρόπλοιο;
        </b>
        <br />
        Βασικά είναι σαν να φτιάχνεις προσχέδια για οποιαδήποτε μεγάλη
        κατασκευή· απλώς αυτό ήταν ένα αστρόπλοιο. Ξέραμε το μέγεθος, ξέραμε τα
        υλικά που είχε στο εσωτερικό του, όπως και στα κατοικίσιμα δωμάτια,
        οπότε αυτό που τελικά χρειαζόταν να κάνουμε ήταν να απλώνουμε μεγάλα
        φύλλα από περγαμηνή σχεδιασμού και ‘mylar’ και να μετράμε και να
        σχεδιάζουμε τα πράγματα σε κλίμακα. Μεγάλο μέρος του πλοίου ήταν
        άγνωστο, φυσικά, οπότε τα σημεία αυτά έπρεπε να τα επινοήσουμε εμείς με
        τη βοήθεια ανθρώπων όπως ο Todd Guenther. Πολλά από τα στοιχεία του
        εσωτερικού ήταν επαναλαμβανόμενα, όπως τα δωμάτια, οπότε ο πραγματικός
        αριθμός των μοναδικών στοιχείων ήταν διαχειρίσιμος.
        <br />
        <br />
        <b>
          Κάνατε επίσης και τα προσχέδια του Enterprise-E. Λόγω διαφόρων
          πραγμάτων που ειπώθηκαν στο <i>First Contact</i> και στο{" "}
          <i>Nemesis</i>, έχει δημιουργηθεί αρκετή σύγχυση σχετικά με τον
          πραγματικό αριθμό των καταστρωμάτων αυτού του πλοίου. Θα μπορούσατε να
          μας διαφωτίσετε σχετικά με το θέμα;
        </b>
        <br />
        Όχι και πολύ. :) Νομίζω ότι είχε να κάνει τελικά με το ότι οι
        σεναριογράφοι δεν το έψαξαν, αλλά αυτό συμβαίνει πού και πού, οπότε το
        σημειώνουμε και προχωράμε.
        <br />
        <br />
        <b>
          Έχετε κάποια αγαπημένα σχέδια ανάμεσα σε όλα τα αστρόπλοια και τους
          σταθμούς που απεικονίζονται στο Star Trek;
        </b>
        <br />
        Από τα πλοία πάνω στα οποία δεν δούλεψα εγώ, το 1701-Refit είναι ένα από
        τα πιο ωραία σχέδια στο σύμπαν του Trek. Εντός της παρτίδας στην οποία
        δούλεψα εγώ, είμαι ακόμη ανάμεσα στο Voyager και στο Vor’Cha class
        Klingon cruiser.
        <br />
        <br />
        <b>
          Το PADD ήταν επίσης μία από τις δημιουργίες σας. Φανταζόσασταν τότε
          ότι θα είχαμε παρόμοιες συσκευές, όπως τα tablet του σήμερα, τόσο
          νωρίς στην πραγματική ζωή;
        </b>
        <br />
        Ήξερα ότι θα εμφανίζονταν, απλά δεν ήμουν σίγουρος ακριβώς πότε. Όλοι
        όσοι σχεδιάζαμε συσκευές για παραγωγές επιστημονικής φαντασίας
        παρακολουθούσαμε για χρόνια τις εξελίξεις στους υπολογιστές. Οπότε τα
        tablet και τα smartphone και όλα αυτά δεν αποτελούν και έκπληξη, αλλά
        είναι πραγματικά καταπληκτικά. Μπορώ τώρα να τρέξω ένα slideshow υψηλής
        ανάλυσης από ένα iPod, και όταν ξεκίνησα στις αρχές της δεκαετίας του
        ‘70 χρειαζόντουσαν δύο προβολείς για διαφάνειες από φιλμ και ένα μία
        μονάδα σβησίματος για να πετύχεις το ίδιο βασικό οπτικό αποτέλεσμα. Κι
        αυτό χωρίς να υπολογίζουμε το τράβηγμα φωτογραφιών με μονοοπτική
        φωτογραφική μηχανή 35 χιλιοστών και τη δημιουργία των διαφανειών.
        <br />
        <br />
        <b>
          Το Star Trek έχει μάλιστα επηρεάσει και σε κάποιες περιπτώσεις ακόμη
          και αποτελέσει το έναυσμα για πραγματικές τεχνολογικές εξελίξεις. Θα
          θέλατε να κάνετε κάποιο σχόλιο επ’ αυτού;
        </b>
        <br />
        Αυτή είναι μια αρκετά γενική δήλωση, αλλά είναι ευρέως γνωστό ότι πολλοί
        άνθρωποι στον χώρο των επιστημών και της μηχανικής έχουν εμπνευστεί από
        προγράμματα όπως το Star Trek και εισήλθαν στα πεδία τους λόγω των
        δυνατοτήτων που παρουσιάζονταν σ’ αυτά τα προγράμματα.
        <br />
        <br />
        <b>
          Ως τεχνικός σύμβουλος των σεναριογράφων, πόσο δύσκολο ήταν να
          διατηρείτε τη χρονολογική συνέχεια ανάμεσα στον τεράστιο αριθμό των
          σεναρίων;
        </b>
        <br />
        Στην πραγματικότητα μού ήταν αρκετά εύκολο να παρακολουθώ όλες τις
        τεχνολογικές ιδέες που δίνονταν στους σεναριογράφους και τους
        παραγωγούς, ειδικά με τους υπολογιστές μας με τους οποίους μπορούσα να
        δημιουργώ και να αποθηκεύω υπομνήματα και σχέδια. Οι σεναριογράφοι ήταν
        καλοί ακροατές, και μπορούσαν να καταλάβουν πώς λειτουργούσαν κανονικά
        τα πλοία και τα συστήματα, βασιζόμενοι στα εσωτερικά μας βιβλιαράκια
        τεχνολογίας και τις σημειώσεις για τα σενάρια. Όταν ήθελαν κάτι να
        ξεφύγει ή να λειτουργήσει διαφορετικά, βρίσκαμε τρόπους και προτείναμε
        λύσεις.
        <br />
        <br />
        <b>
          Πώς επινοήσατε όλη την τεχνική ορολογία που έχετε εισάγει αυτά τα
          χρόνια;
        </b>
        <br />
        Παρακολουθώ την επιστήμη και την τεχνολογία από τότε που ήμουν παιδί,
        βλέπω ταινίες επιστημονικής φαντασίας, ντοκιμαντέρ, διαβάζω διαστημικά
        βιβλία, φτιάχνω μοντέλα πυραύλων, και ούτω καθεξής. Ασχολήθηκα με την
        τέχνη αντί για αυτήν καθαυτήν τη μηχανική, αλλά θα πρέπει να θυμάται
        κανείς ότι κάθε μεγάλο διαστημικό εγχείρημα με το οποίο έχουμε ασχοληθεί
        ποτέ ξεκίνησε με τεχνικό σχέδιο. Εκτός από το να ξέρεις πώς να
        σχεδιάζεις και να ζωγραφίζεις, είναι καλό να ξέρεις για υλικά και
        διαδικασίες, αναλογίες ώθησης-προς-βάρος, συστήματα παραγωγής πρόωσης
        και ενέργειας, περιβαλλοντικό έλεγχο και ζητήματα υποστήριξης ζωής,
        ουράνια μηχανική και καθοδήγηση και πλοήγηση. Λίγο απ’ όλα· κυρίως
        διαβάζοντας και ακούγοντας και μιλώντας με ειδικούς όλα τα χρόνια.
        <br />
        <br />
        <b>
          Συν-γράψατε τα <i>Τεχνικά Εγχειρίδια</i> του{" "}
          <i>The Next Generation</i>, του <i>Deep Space Nine</i> και του{" "}
          <i>Voyager</i>. Πώς καταφέρατε να διατηρείτε επιστημονική αληθοφάνεια
          στις έννοιες που περιγράφονται τόσο στις σειρές όσο και στα βιβλία;
          Γιατί δεν έχει εκδοθεί το <i>Τεχνικό Εγχειρίδιο του Voyager;</i>
        </b>
        <br />
        Το γράψιμο των τεχνικών εγχειριδίων συνδέεται στην πραγματικότητα με την
        προηγούμενη απάντηση. Όταν γνωρίζεις κάποια πράγματα σχετικά με το πώς
        λειτουργούν τα αληθινά διαστημικά συστήματα, δεν είναι πολύ δύσκολο να
        επινοήσεις καινούρια, να εξάγεις υποθέσεις για τις εξελίξεις στη φυσική
        και άλλους τομείς ώστε να δώσεις στους αναγνώστες μερικές ενδιαφέρουσες
        ιδέες για το μέλλον. Υπάρχουν διάφορες διανοητικές λεπτομέρειες που
        όντως μπαίνουν σε ένα νέο φανταστικό εργαλείο ή σύστημα, όπως υλικά,
        απαιτούμενες ενέργειες, χρόνος κατασκευής, μάζα, και ούτω καθεξής, αλλά
        πολλές από αυτές μπορούν να καθοριστούν ως εσωτερικοί κανόνες κατά τη
        διάρκεια της παραγωγής. Και είναι αυτή ακριβώς η εσωτερική συνέπεια που
        έχει αποτελέσει σήμα κατατεθέν του Star Trek ήδη από το ξεκίνημά του.
        <br />
        Σχετικά με το εγχειρίδιο του Voyager, έχει να κάνει με τον χρόνο και τις
        χρηματικές πηγές που οι δικαιούχοι των εκδόσεων μπορεί να θέλουν να
        διαθέσουν σε σχέση με πιθανές πωλήσεις, και μέχρι τώρα δεν έχουν δείξει
        αρκετό ενδιαφέρον. Τόσο απλό.
        <br />
        <br />
        <b>
          Ποιος από όλους τους τύπους δουλειάς που κάνατε για το Star Trek -ο
          σχεδιασμός πλοίων και σταθμών, η δημιουργία προσχεδίων, η κατασκευή
          μοντέλων και αντικειμένων, η εικονογράφηση κτλ.- ήταν ο πιο
          ικανοποιητικός για σας;
        </b>
        <br />
        Έιναι όλα ικανοποιητικά, καθώς αποτελούν όλα μία μεγάλη διαδικασία
        δημιουργίας πραγμάτων όπως φαίνεται από το τίποτα. Οι εσωτερικοί κανόνες
        στους οποίους αναφέρθηκα συγκρατούν όλο το πράγμα από το να καταλήξει να
        γίνει βλακώδες, ακόμη κι όταν αυτό συμπεριλαμβάνει την αποδοχή κάποιων
        λίγο μη-αληθοφανών εννοιών για χάρη του δράματος, και αυτό μου αρέσει.
        <br />
        <br />
        <b>
          Θα θέλατε να μοιραστείτε μαζί μας κάποιες από τις αγαπημένες σας
          στιγμές από όλα αυτά τα χρόνια που δουλεύατε στο Star Trek;
        </b>
        <br />
        Υπάρχουν μερικές ιστορίες που έχω διηγηθεί κάποιες φορές, σίγουρα. Τις
        πολύ πρώτες μέρες που δουλεύαμε με υπολογιστές Macintosh, ο Patrick
        Stewart ερχόταν πάνω στο καλλιτεχνικό τμήμα και έκανε στον Mike Okuda
        και σ’ εμένα διάφορες τεχνολογικές ερωτήσεις για το δικό του Mac. Μια
        φορά αναρωτήθηκε, ‘Ξέρω ότι με το Command-X μπορείς να εξαφανίσεις
        κάποιο κείμενο... και ξέρω ότι μπορείς να το επαναφέρεις, αλλά πού
        πηγαίνει; Είναι πολύ ανησυχητικό.’
        <br />
        Στο Star Trek: The Motion Picture, ο σκηνοθέτης Robert Wise σταμάτησε
        σκόπιμα το γύρισμα, περιμένοντας να έρθω στο πλατό για να πω στον Jimmy
        Doohan (Μηχανικός Montgomery Scott) ποια κουμπιά να πατήσει στην άκατο
        του Αστροστόλου· είχα σχεδιάσει εγώ τις οθόνες αφής.
        <br />
        <br />
        <b>
          Έχετε δουλέψει για πολλά επιστημονικά περιοδικά και ερευνητικά κέντρα.
          Με ποιον τρόπο διαφέρει αυτή η δουλειά από τη δημιουργία σχεδίων για
          την τηλεόραση και τον κινηματογράφο;
        </b>
        <br />
        Μοιάζει πολύ, αλλά διαφέρει λίγο στο ότι μπαίνει στο παιχνίδι πιο
        ρεαλιστική επιστήμη και μηχανική. Όταν απεικονίζεις ιστορικές ή
        μελλοντικές σκηνές διαστημικής εξερεύνησης, είναι καλό να γνωρίζεις για
        τα πραγματικά διαστημικά συστήματα που ανέφερα νωρίτερα, και για εμάς
        που είμαστε ειδικότερα καλλιτέχνες του αστρονομικού χώρου, είναι επίσης
        καλό να ξέρουμε για τους πλανήτες και τα φεγγάρια και άλλα αστρικά
        αντικείμενα τα οποία απεικονίζουμε. Μερικοί διεισδύουμε πολύ σε τομείς
        που αφορούν κρατήρες, ηφαίστεια, κομήτες, ατμόσφαιρες γιγάντων αερίων,
        σχηματισμό και εξέλιξη αστέρων, και σχετικά θέματα.
        <br />
        <br />
        <b>
          Δουλέψατε για το <i>Cosmos</i> του Carl Sagan. Ποια είναι η γνώμη σας
          για την πρόσφατη αναβίωση της σειράς;
        </b>
        <br />
        Πιστεύω ότι η νέα σειρά είναι μια αξιοπρεπής εισαγωγή στο σύμπαν και
        μερικές από τις προσωπικότητες που το μελέτησαν. Δεν βρήκα αυτήν τη
        δεύτερη εκδοχή τόσο διανοητικά προκλητική όσο την πρώτη, και κάποια από
        τα CGI εφέ μου φάνηκαν απογοητευτικά. Θα πρότεινα σε παιδιά και σε
        ενηλίκους που δεν έιναι πολύ εξοικειωμένοι με το υλικό να
        παρακολουθήσουν την καινούρια σειρά, μετά να δουν την πρώτη και να
        διαβάσουν το αρχικό βιβλίο COSMOS. Θα αποκομίσουν πολλά περισσότερα από
        την εμπειρία.
        <br />
        <br />
        <b>Με τι ασχολείστε αυτόν τον καιρό;</b>
        <br />
        Σταμάτησα για λίγο μετά από ένα τρέξιμο 42 ετών για να ξαναοργανωθώ, να
        επιλέξω κάποια πρότζεκτ μοντέλων σε σκίτσα, CG, και φυσικά μοντέλα, και
        να αποκαταστήσω την επαφή μου με φίλους και πελάτες. Έχω και μερικά
        πράγματα ακόμη για το προσεχές μέλλον.
      </p>
    </>
  );
}
