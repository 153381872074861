import React from "react";
import InterviewGallery from "../InterviewGallery";

export default function Drexler() {
  let photos = [];
  for (let i = 0; i < 10; i++) {
    photos.push(`photos/Drexler/drex${i + 1}.jpg`);
  }

  return (
    <>
      <div>
        <h3 className='centered headerRad'>Doug Drexler's Interview</h3>
      </div>
      <p>
        <b>
          How did you get involved in Star Trek? How was it for you, getting
          from being a fan to actually work on Star Trek?
        </b>
        <br />
        I was an original fan from 1966. In fact I watched the entire first
        season in Black and White. Science fiction was not popular then. It was
        kind of a small club. Being a dedicated fan for so many years, and then
        getting to work on Trek for decades was supernatural. I’m the luckiest
        kid ever.
        <br />
        <br />
        <b>
          You did so many things in Star Trek that it’s hard to even list them
          all. How did you initially move from makeup into the art department
          and what prompted you to do so?
        </b>
        <br />
        I’m much too curious and excited about life to ever have one career. Too
        much going on to limit yourself. Being on the Enterprise-D sets day and
        night as a makeup guy gave me a chance to examine the design and
        workmanship. I was a sketcher and designer, and knew that I had to be a
        part of THAT art department. It was in my DNA. I was particularly blown
        away by the interfaces and graphics. They were so clever, so stylish, so
        authentic. I knew about Mike Okuda, and admired him immensely. He was a
        big part of what gave Trek its sense of reality. One day on Stage 10
        (Planet Hell), I saw him at the craft service table getting a cup of
        coffee. I think I embarrassed him. I gushed my admiration.
        <br />
        <br />
        <b>
          You played a leading role in the campaign to save TOS from
          cancellation after its 2nd season and to bring it back to life after
          its third and final season. Can you tell us a little about this
          experience?
        </b>
        <br />
        I had gotten a letter from Bjo Trimble, who worked for Roddenberry and
        Lincoln Enterprises. It was what instigated the famous letter writing
        campaign. I was horrified that the show might be cancelled. Didn’t we
        have enough westerns, and cop shows? Trek was singular. I started
        organizing kids to get writing letters. I was 13 I guess. I even ended
        up in several newspapers. I learned a lot from that. I learned that you
        could make an impact.
        <br />
        <br />
        <b>
          More than 45 years later, you’re in the front lines of yet another
          struggle, one for a fifth season of Enterprise on Netflix. What do you
          think are the possibilities of this goal to be realized?
        </b>
        <br />
        I’ll be honest. If Paramount is going to invest in a new show, they will
        most likely want to do something all new. They already know
        approximately how an Enterprise TV revival will do. Odds are they will
        be prone to do something new, with unknown potential, that might go
        through the roof.
        <br />
        <br />
        <b>
          You designed the Enterprise NX-01 from scratch. What were the basic
          characteristics you wanted it to have? What prompted you later to also
          design a refit for the ship?
        </b>
        <br />I wanted it to have a sense of evolution. To be able to see the
        “steps”, the gradual development toward TOS. Not having a secondary hull
        said it loud and clear. From the very beginning I had a refit in mind.
        While designing it I would stop and place a secondary hull, just to make
        sure it would balance. In my mind, plans for the refit were on the
        boards at starfleet even before the NX launched. I finished designs for
        the refit some years after Enterpise was cancelled. I had been editing
        the <i>Ships of the Line</i> Calendars for a decade, and thought an NX
        refit would be exciting. The prime timeline still growing in spite of
        being benched. Seemed kind of heroic. It made sense. A few years “out
        there” would show Starfleet which way to go. The design team knew they
        were better off planning a two stage development. Go out, get your butt
        kicked, learn, come back, refit to to better handle the mission profile.
        Besides, the Romulan War was on the horizon. It was a necessity.
        <br />
        <br />
        <b>
          You also designed the awesome, possible-future Enterprise NCC-1701-J.
          Would you tell us a little about how this design came to be and how
          you imagine this starship’s capabilities and life aboard?
        </b>
        <br />
        I knew that it would need to be a quantum leap beyond, while still
        keeping the recognizable “Enterprise” design ethic. The way to do that
        is to give it characteristics that make people say, “... no way! Those
        pylons are too spindly! A ship 2 miles long!? That’s stupid!”. In fact,
        I had taken note of reactions to the Enterprise back in the 60s... same
        stuff... those spindly nacelle struts, and a length of a thousand feet
        struck grounded people back then as ridiculous fantasy. If you don’t
        surprise people, or make them dubious, you haven’t stretched enough. I
        mean, think about it, the U.S. Navy thought airplanes were ridiculous,
        even after Billy Mitchell sunk a battleship.
        <br />
        I saw this concept as a multi-generational vessel, that had large parks,
        entertainment zones, and entire universities on board. The ship is so
        large that turbo lifts would be replaced with site-to-site transporters.
        I opted for spindly nacelle struts because I felt it suggested a
        technology beyond what we were familiar with. Matt Jefferies used this
        same gag on the original series ship with its impossibly thin engine
        supports. Its nacelles had a floaty appearance, defying the laws of
        physics. It was my opinion that the ever heavying up of engine struts
        over the years, took some of the magic out of the Enterprise.
        <br />
        <br />
        <b>
          How do you proceed on designing a starship in general? How long does
          it usually take for you to come up with and finish a design?
        </b>
        <br />
        It takes as long as you’ve got. The J was designed in three days,
        because that’s what I had. The NX was designed over several months. Same
        deal there.
        <br />
        You start by getting a feel for the show in general. Its time frame, the
        mission profile, etc. Since you are designing to meet the eye of the
        producer and production designer, you show them as many ideas as you
        can, gleaning from their reactions what they want. It’s awesome when
        fans design starships, but they don’t know what it’s like to design for
        the producer, the production designer, yourself, and the studio. A whole
        other challenge that makes designing a ship on your own look like a
        birthday party.
        <br />
        <br />
        <b>
          What do you think is the most important part in the design of a
          Starship? Do you have a favorite Starship Class among all those
          presented in Star Trek?
        </b>
        <br />
        I love the original Enterprise above and beyond any of the other
        restylings that followed. Don’t get me wrong. I love the Ships that
        followed in the prime timeline. They all kept that most important
        aesthetic: thoughtfulness. They followed Gene’s believably decree. At
        the time of the original, nothing like it had ever been seen before. The
        idea that it never landed was revolutionary. It was quite simply a
        mind-boggling feat of design. Nothing before or since has been so
        original. It still stands up to, and exceeds stuff being done today.
        Usually science fiction designs look pretty creaky after 15 years. The
        Enterprise still looks futuristic after 50.
        <br />
        <br />
        <b>
          How was the experience of re-building Original Series’ U.S.S.
          Enterprise sets for <i>Relics</i>, <i>Trials and Tribble-ations</i>,
          and <i>In a Mirror, Darkly</i>?
        </b>
        <br />
        As you might imagine, those were high-watermarks for us. Between me,
        Mike, and Denise, those shows were all heart and soul. I mean, we had a
        fantastic art department. They were all stars. The three of us however,
        ate, slept, and drank it. Mike and I served as art directors on those
        shows, in respect to anything TOS. We’d been studying for this nearly 30
        years.
        <br />
        At the time of DS9, it was still stone knives, and bear skins. We were
        using video tapes, and we had a big heavy machine that would spit out
        low quality frame grabs. The Internet was a glint. I had kept an archive
        of film clips, articles, and photos. That was our main source of
        reference. What a great time we had. It was uncanny. Today, there are a
        couple of TOS set replicas built by fans. Back then almost nothing, so
        it was extraordinary.
        <br />
        I remember that we had a terrible time trying to get a hold of the
        actual red screen that was used on the original show. We eventually did
        locate it, but not in time to use it on Tribble-ations. What we ended up
        using was by happy, ironic, and serendipitous accident. Mike and I were
        leaving the stage feeling defeated… the actual screen was nowhere to be
        found and time was running out. Just outside of the stage door, backlot
        was digging a trench to replace an underground pipeline. There, being
        used to cordon the excavation off was a flexible vinyl mesh. I grabbed
        Mike by the shoulders. Speechless, I pointed dumbly at the mesh. Within
        minutes, Mike and I had returned with a pair of industrial snippers, and
        snipped ourselves off an appropriate square of the material. It reminded
        me of that scene in the “Right Stuff”, where Gridley saws off a
        workman’s broom for Yeager to use as a door handle.
        <br />
        When we finished DS9’s “Trial and Tribble-ations”, we figured that was
        it. There is no chance that we will ever get to play in that universe
        again! Well, when you live in a sci-fi world like we do, you eventually
        know better! When Manny Coto and Mike Sussman got their hands on the NX
        wheel, Enterprise made an abrupt course change, and we returned home yet
        again. Since this was the USS Defiant, and the footage we shot did not
        need to intercut with an original series episode as was the case with
        Tribble-ations, our beloved production designer Herman Zimmerman decided
        it would be fair to sleek the classic designs up a bit, as if Matt
        Jefferies had some money back in the day. Updated, but not likely to
        raise the ire of the most hardened purist. That was always our goal. You
        must go the extra mile for the hard core fans.
        <br />
        <br />
        <b>
          You did a cameo appearance in the <i>Enterprise</i> final episode.
          What were your feelings about the series being cancelled and what was
          the reason for its cancellation, to your knowledge?
        </b>
        <br />
        It was premature, and more than a little politics. The show was getting
        3 million viewers, which on a network that wasn’t coast to coast, that’s
        pretty good. We were getting 3 mil on Galactica, and that was a hit for
        SyFy. Hey... the only other Star Trek to be killed by politics was TOS.
        <br />
        Naturally we were all VERY sad. VERY sad. We had been together for
        nearly two decades, and that is a family. That production was about as
        tight as it gets. We knew it wasn’t just about us, however. We knew
        there was a possibility that if Trek came back it might be
        unrecognizable. I think that was our biggest fear. We were that
        dedicated to Roddenberry’s brainchild, and still are. It’s in our DNA.
        It was never a job.
        <br />
        <br />
        <b>
          Apart from cameos in official Star Trek productions, you also played a
          Holodeck character in <i>Star Trek Continues</i>. What do you think of
          this series and of fan productions in general?
        </b>
        <br />
        I’m one of the fathers of the Star Trek fan film phenomenon. I was part
        of <i>Star Trek New Voyages</i>. There wasn’t even a Youtube yet.
        <br />
        For the most part I don’t watch any of them. I’m much too opinionated,
        and I feel proprietary. It would be like watching someone else taking
        Dorothy on a date. Star Trek Continues is different. It is so on the
        nose it’s uncanny, AND it ain’t about space wars, and visual effects. I
        do all of their Enterprise VFX shots. I’m all about recreating the
        original. I don’t want to fix it, or prove how much smarter I am than
        they were 50 years ago. I just want to be true. I treat it as if I was
        called in to do season 4. I know what Bob Justman would have said to me,
        “...the new shots must cut in with the old, because I’m not throwing
        away my stock!”. STC is a museum quality recreation. Inside and outside
        the ship. That’s hard to resist.
        <br />
        <br />
        <b>
          Of all the things you have done in Star Trek, what was the most
          challenging and what did you enjoy the most? Is there some
          contribution you made you consider your favorite?
        </b>
        <br />
        Well... of course, the NX. But there are SO many things I’m thrilled
        about.
        <br />
        <br />
        <b>
          During all those years of working on Star Trek, is there something you
          wish you had done differently?
        </b>
        <br />
        As an artist, you always think you might have done it better, or
        differently. It’s part of the process.
        <br />
        <br />
        <b>
          <i>Drex Files</i> was a fantastic blog that provided us with the rare
          opportunity to see all these awesome designs in detail. What was the
          reason it came off-line?
        </b>
        <br />
        There were some VFX artists whose work appeared on the blog who felt I
        was taking credit for their work. I could never! Honestly, I don’t
        really need to steal credit for some CG model! I have plenty of gold to
        show! I hope that they manage to be happier people. All in all, it left
        a bitter taste in my mouth. I discontinued the Drex Files after that.
        <br />
        <br />
        <b>
          As a fan, do you have any favorite episodes or characters from the
          Star Trek Universe?
        </b>
        <br />
        Egad! There are just too many things that come to mind. It’s really hard
        to have favorites.
        <br />
        <br />
        <b>
          Philosophy, humanism, stories, characters, actors, starships,
          technology... Which aspect of Star Trek do you think most appeals to
          the fans?
        </b>
        <br />
        Certainly all of those things appeal in varying degrees to different
        people. They are the main ingredients of authentic Star Trek.
        <br />
        <br />
        <b>
          Many of us feel that J.J. Abrams’ <i>Star Trek</i> deviates from the
          basic Trek principles and its true spirit. Would you like to comment
          on that?
        </b>
        <br />
        I think a lot of people know that I am not a fan of JJ’s Star Trek. I
        fully recognize that they are dandy action flicks, and I know that they
        serve as a “gateway drug” to the older stuff for many people. To me it
        feels like Star Trek with a personality transplant. That “soul” it had
        is not there. Even at the previous shows’ worst, they all still had that
        spark of intelligence and wonder abut them. Many times I watched the TV
        episodes and felt proud of some of the social and human statements they
        made. I read an interview with JJ where he states that he hated it when
        Trek got all philosophical and stuff. That was like an arrow in my
        heart.
        <br />
        <br />
        <b>What do you think could, or should be, the future of Star Trek?</b>
        <br />
        For one thing, Star Trek wants to be a lot of stories, week after week.
        Science Fiction is for exploration, and oblique thought. Television is
        where that is happening these days. Movies are generally dumb, and about
        big fat explosions, people jumping off buildings, and catching bullets.
        Television has become the younger smarter brother. Another important
        thing is who you hire to be a showrunner. It wants to be someone who
        respects the tapestry that Trek has woven over 50 years. In my opinion
        it’s just egotistical to have thrown all of that away! JJ, I say no!
        Star Trek should be constantly asking questions, and asking questions
        that sometimes make people uncomfortable.
        <br />
        <br />
        <b>
          You have also worked, among various other productions, on the
          re-imagined Battlestar Galactica and it’s subsequent prequels. Would
          you tell us a little about your involvement in those productions?
        </b>
        <br />
        Once upon a time, back in the days when metal was made of wood, visual
        effects were created using miniatures. I consider myself lucky to have
        gotten started in the business when miniatures were still being used
        exclusively. VFX supervisors, like the amazing Gary Hutzel, have a
        distinct advantage over those who have only worked in the digital world.
        Gary knows how things work in real life. He knows how light really
        behaves. Speaking of Gary Hutzel, we met on TNG when I was a makeup
        artist. The first time I met him, I knew I liked him. Aside from being
        talented, he was funny, and there is nothing better than funny. When I
        jumped to the art department at the start of DS9, I got to know Gary a
        whole lot better. The Star Trek Art Department in those days worked hand
        in glove with VFX. We made it our business to be a resource for them.
        Gary knew that if his budget didn’t cover a model of Starfleet Command
        in San Francisco, we would make one for him out of bird feeders and CD
        racks.
        <br />
        So I got to know Gary very well. One day he came into the art
        department, and saw me fiddling with Lightwave. I saw the lightbulb go
        off over his head. After that he started having me do stuff for the show
        inhouse. We had a lot of fun. I remember saying that I hoped we got a
        chance to do that again. He said, “... be careful what you wish for!”.
        <br />
        When Enterprise was cancelled, I remember driving home thinking that
        maybe the supernaturally long run was over. As soon as I opened the
        door, Dorth said that Gary Hutzel left me a message. I was like, holy
        cow! He’s going to ask me to join him on Galactica. I think it’s been
        more than ten years now! Galactica is simply one of the best Space
        Operas EVER. I’m amazed at how many Trek fans won’t watch it out of
        loyalty to Star Trek. First, Ron Moore’s Galactica is a direct
        descendant of Trek. It reflects everything Ron learned working on Star
        Trek. If you haven’t seen it, watch the mini series. Do yourself a
        favor.
        <br />
        So yes, we did a bunch of years doing Galactica, Caprica, Blood &
        Chrome, and currently Defiance, on SyFY. I’m one lucky kid!
      </p>

      <br />
      <InterviewGallery photos={photos} />
    </>
  );
}
