import React from "react";
import Video from "../Video";

export default function Nemecek() {
  return (
    <>
      <div>
        <h3 className='centered headerRad'>Larry Nemecek's Interview</h3>
      </div>
      <div className='sectionStyle'>
        <Video
          video={{
            title: "A chat with Larry Nemecek, part 1",
            link: "https://www.youtube.com/embed/ugne9Ju0Ecs?rel=0",
          }}
        />
        <Video
          video={{
            title: "A chat with Larry Nemecek, part 2",
            link: "https://www.youtube.com/embed/GBx7nx8EenI?rel=0",
          }}
        />
        <Video
          video={{
            title: "A chat with Larry Nemecek, part 3",
            link: "https://www.youtube.com/embed/GoQ8TTqS8k0?rel=0",
          }}
        />
      </div>
    </>
  );
}
