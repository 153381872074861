import React from "react";

const imgStyle = {
  margin: "10px",
  maxWidth: "30%",
  height: "auto",
  objectFit: "contain",
};

/* eslint-disable */
function InterviewGallery({ photos }) {
  if (!Array.isArray(photos)) {
    console.error("Expected photos to be an array of strings");
    return null;
  }

  return (
    <>
      <h3 className='centered headerRad'>Gallery</h3>
      <div className='sectionStyle container centered'>
        {photos.map((path, index) => (
          <img
            key={index}
            style={imgStyle}
            src={path}
            alt={`Photo ${index + 1}`}
          />
        ))}
      </div>
    </>
  );
}

export default InterviewGallery;
