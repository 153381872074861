import React from "react";
import Video from "../Video";

export default function Moore() {
  return (
    <>
      <div>
        <h3 className='centered headerRad'>Ronald B. Moore's Interview</h3>
      </div>

      <div className='sectionStyle'>
        <Video
          video={{
            title: "A chat with Ronald B. Moore",
            link: "https://www.youtube.com/embed/O-2hwe8wJcs?rel=0",
          }}
        />
      </div>
    </>
  );
}
