import React, { Component, useEffect, useState } from "react";
import "./App.css";
import NavBar from "./Components/NavBar";
import InterviewRouter from "./Components/InterviewRouter";

function App() {
  const centerSmall = {
    display: "flex",
    justifyContent: "center",
    fontSize: "11px",
  };

  const [theme, setTheme] = useState("light");

  const handleThemeChange = (e) => {
    setTheme(e.matches ? "dark" : "light");
  };

  useEffect(() => {
    const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
    setTheme(darkThemeMq.matches ? "dark" : "light");
    darkThemeMq.addEventListener("change", handleThemeChange);
    return () => {
      darkThemeMq.removeEventListener("change", handleThemeChange);
    };
  }, []);

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  return (
    <>
      <NavBar />
      <InterviewRouter />
      <hr />
      <div style={centerSmall}>Website programmed by Dimitris Psathas</div>
      <div style={centerSmall}>
        This website does not use cookies and does not collect user data of any
        kind
      </div>
    </>
  );
}

export default App;
