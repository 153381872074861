import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Bormanis from "./Interviews/Bormanis";
import Drexler from "./Interviews/Drexler";
import Hertzler from "./Interviews/Hertzler";
import Moore from "./Interviews/Moore";
import Nemecek from "./Interviews/Nemecek";
import Sternbach from "./Interviews/Sternbach";
import Stillwell from "./Interviews/Stillwell";

function InterviewRouter() {
  return (
    <div className='container' style={{ marginTop: "16px" }}>
      <Router>
        <nav>
          <ul className='nav-container'>
            <Link className='routeBtn' to='/andrebormanis'>
              André Bormanis
            </Link>
            <Link className='routeBtn' to='/dougdrexler'>
              Doug Drexler
            </Link>
            <Link className='routeBtn' to='/jghertzler'>
              J.G. Hertzler
            </Link>
            <Link className='routeBtn' to='/ronaldbmoore'>
              Ronald B. Moore
            </Link>
            <Link className='routeBtn' to='/larrynemecek'>
              Larry Nemecek
            </Link>
            <Link className='routeBtn' to='/ricksternbach'>
              Rick Sternbach
            </Link>
            <Link className='routeBtn' to='/ericstillwell'>
              Eric Stillwell
            </Link>
          </ul>
        </nav>

        <Routes>
          <Route path='/andrebormanis/' element={<Bormanis />} />
          <Route path='/dougdrexler/' element={<Drexler />} />
          <Route path='/jghertzler/' element={<Hertzler />} />
          <Route path='/ronaldbmoore/' element={<Moore />} />
          <Route path='/larrynemecek/' element={<Nemecek />} />
          <Route path='/ricksternbach/' element={<Sternbach />} />
          <Route path='/ericstillwell/' element={<Stillwell />} />
        </Routes>
      </Router>
    </div>
  );
}

export default InterviewRouter;
